import React from "react";
import { MapPin, Plane, Clock, Users } from "lucide-react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./App.module.css";

const TravelAgencyLandingPage = () => {
  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="bg-white sticky top-0 z-50 bg-white shadow-md">
        <div className="max-w-full mx-auto px-4 py-4 flex justify-between items-center">
          <div className="text-2xl font-bold text-blue-600 w-20 h-20 justify-center items-center flex rounded-full p-2">
            <img
              src="Logo.png"
              width={80}
              alt="Logo"
            />
          </div>
          <div className="space-x-6 mr-6">
            <a
              href="#paquetes"
              className="hover:text-blue-600 text-xl font-semibold"
            >
              Paquetes
            </a>
            <a
              href="#servicios"
              className="hover:text-blue-600 text-xl font-semibold"
            >
              Servicios
            </a>
            <a
              href="#contacto"
              className="hover:text-blue-600 text-xl font-semibold"
            >
              Contáctanos
            </a>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <header className={classes.header}>
        <div className="max-w-6xl mx-auto px-4 py-24 text-center">
          <h1 className="text-5xl font-extrabold mb-6">
            Haciendo de tus sueños las mejores experiencias
          </h1>
          <p className="text-xl font-semibold mb-8">
            Recuerdos que valen la pena
          </p>
          <div className="text-center">
            <a
              href="https://wa.me/+573042169147?&text=Hola, quiero más información sobre uno de sus servicios"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-flex items-center justify-center px-8 py-3 overflow-hidden font-bold text-white rounded-full transition duration-300 ease-out 
              bg-green-600 hover:bg-green-700 
              shadow-lg hover:shadow-green-500/50 
              transform hover:-translate-y-1 
              active:translate-y-0"
            >
              <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full group-hover:translate-x-0 ease">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6 mr-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.625c0-.75-.333-1.434-.86-1.987A6.61 6.61 0 0 1 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z"
                  />
                </svg>
                WhatsApp
              </span>
              <span className="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease">
                Contáctanos
              </span>
              <span className="relative invisible">Contáctanos</span>
            </a>
          </div>
        </div>
      </header>

      {/* Destacados */}
      <section
        id="paquetes"
        className="py-16"
      >
        <div className="max-w-full mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-12">
            Paquetes Destacados
          </h2>
          <div className="flex w-full px-14 h-[450px]">
            <Swiper
              spaceBetween={20}
              slidesPerView={1}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
              }}
              pagination={{ clickable: true }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log("slide change")}
              modules={[Navigation, Pagination]}
              className={classes.swiperWrapper}
            >
              {[
                {
                  title: "Capurganá",
                  description: "Mezcla perfecta entre mar, rios y selva",
                  price: "1'559.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/capurgana.jpg",
                  details:
                    "https://reservaexperiencias.s3.us-east-1.amazonaws.com/Capurgana+2025.pdf",
                  time: "4 días / 3 noches ",
                },
                {
                  title: "Coveñas",
                  description: "La magia esta en vivirlo",
                  price: "569.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/Covenas.jpeg",
                  details:
                    "https://reservaexperiencias.s3.us-east-1.amazonaws.com/Covenas+2025.pdf",
                  time: "4 días / 3 noches",
                },
                {
                  title: "Isla fuerte",
                  description: "Joya escondida del Caribe colombiano",
                  price: "989.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/islafuerte.webp",
                  details:
                    "https://reservaexperiencias.s3.us-east-1.amazonaws.com/Isla+Fuerte+2025.pdf",
                  time: "4 días / 3 noches",
                },
                {
                  title: "Guajira",
                  description: "Exótica y fantástica",
                  price: "1'789.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/Guajira.png",
                  details:
                    "https://reservaexperiencias.s3.us-east-1.amazonaws.com/Guajira+2025.pdf",
                  time: "7 días / 6 noches",
                },
                {
                  title: "Guatapé",
                  description: "Paraíso de Colores",
                  price: "119.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/Guatape.png",
                  time: "Día de sol",
                },
                {
                  title: "Hacienda Nápoles",
                  description: "Parque temático, diversión y aventura",
                  price: "230.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/pulponapoles.webp",
                  time: "Día de sol",
                },
                {
                  title: "Mutatá",
                  description: "Vive la Colombia inexplorada",
                  price: "487.000",
                  image:
                    "https://reservaexperiencia.s3.us-east-1.amazonaws.com/Mutata.png",
                  time: "2 días / 1 noche",
                },                
              ].map((pack, index) => (
                <SwiperSlide key={index}>
                  <div className="relative group bg-white rounded-lg shadow-md overflow-hidden transform transition-all duration-300 hover:scale-105 hover:shadow-xl">
                    {/* Imagen con efecto de escala y oscurecimiento al hover */}
                    <div className="relative overflow-hidden">
                      <img
                        src={pack.image}
                        alt={pack.title}
                        className="w-full h-56 object-cover transition-transform duration-300 group-hover:scale-110 group-hover:brightness-75"
                      />

                      {/* Overlay de información al hover */}
                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-300 flex items-center justify-center opacity-0 group-hover:opacity-100">
                        <div className="text-white text-center p-4 transform translate-y-10 group-hover:translate-y-0 transition-transform duration-300">
                          <p className="text-sm mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                            {pack.description}
                          </p>
                          <div className="flex justify-center space-x-2 opacity-0 group-hover:opacity-100 transition-opacity duration-700">
                            {pack.details && (
                              <button
                                onClick={() => window.open(pack.details)}
                                className="bg-white text-blue-600 px-3 py-1 rounded-full text-sm hover:bg-blue-100 transition-colors"
                              >
                                Más Detalles
                              </button>
                            )}
                            <button
                              className="bg-green-500 text-white px-3 py-1 rounded-full text-sm hover:bg-green-600 transition-colors"
                              onClick={() => {
                                window.open(
                                  `https://wa.me/+573042169147?&text=Buen día, me gustaría hacer una reserva del paquete para ${pack.title}`
                                );
                              }}
                            >
                              Reservar
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Información base de la tarjeta */}
                    <div className="p-6">
                      <h3 className="text-xl font-bold mb-2">{pack.title}</h3>
                      <div className="flex justify-between items-center flex-wrap gap-2">
                        <span className="text-blue-600 font-bold text-xl">
                          Desde ${pack.price}
                        </span>
                        {pack.time && (
                          <div className="flex items-center space-x-2 text-gray-600">
                            <Clock className="h-5 w-5 text-blue-500" />
                            <span>{pack.time}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}{" "}
            </Swiper>
          </div>
        </div>
      </section>
      {/* Servicios */}
      <section
        id="servicios"
        className="py-16 bg-gray-50"
      >
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-12">Nuestros Servicios</h2>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
              <Plane
                className="mx-auto text-blue-600 mb-4"
                size={48}
              />
              <h3 className="font-bold mb-2">Vuelos</h3>
              <p>Encuentra los mejores vuelos a cualquier destino</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
              <MapPin
                className="mx-auto text-green-600 mb-4"
                size={48}
              />
              <h3 className="font-bold mb-2">Paquetes</h3>
              <p>Explora los destinos más increíbles del mundo</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
              <Clock
                className="mx-auto text-yellow-600 mb-4"
                size={48}
              />
              <h3 className="font-bold mb-2">Reservas</h3>
              <p>Reservaciones rápidas y sencillas</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition">
              <Users
                className="mx-auto text-purple-600 mb-4"
                size={48}
              />
              <h3 className="font-bold mb-2">Grupos</h3>
              <p>Paquetes especiales para grupos y familias</p>
            </div>
          </div>
        </div>
      </section>
      {/* Contacto */}
      <section
        id="contacto"
        className="bg-white py-16"
      >
        <div className="max-w-6xl mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-8">Contáctanos</h2>
          <p className="text-lg text-gray-600 mb-12">
            ¿Tienes alguna pregunta o deseas más información sobre nuestros
            servicios? No dudes en ponerte en contacto con nosotros. Estaremos
            encantados de ayudarte.
          </p>
          <div className="mb-8 text-center">
            <a
              href="https://wa.me/+573042169147?&text=Hola, quiero averiguar sobre uno de sus servicios"
              target="_blank"
              rel="noopener noreferrer"
              className="group relative inline-flex items-center justify-center px-8 py-3 overflow-hidden font-bold text-white rounded-full transition duration-300 ease-out 
              bg-green-600 hover:bg-green-700 
              shadow-lg hover:shadow-green-500/50 
              transform hover:-translate-y-1 
              active:translate-y-0"
            >
              Escribenos en WhatsApp
            </a>
          </div>
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Mapa de ubicación */}
            <div className="max-w-xl mx-auto mb-8 md:mb-0">
              <h3 className="text-xl font-semibold mb-4">Nuestra Ubicación</h3>
              <iframe
              title="location"
                width="400"
                height="450"
                style={{ border: 0 }}
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed/v1/place?key=AIzaSyD8U2rkjLztRj3kOcCcF9htoUskFcXSq8c&q=Reserva+Experiencia+Agencia+de+Viajes+y+Turismo"
              ></iframe>
            </div>

            {/* Información de contacto */}
            <div className="flex flex-col justify-center items-center max-w-md mx-auto">
              <h3 className="text-xl font-semibold mb-4">
                Información de Contacto
              </h3>
              <p className="text-gray-600 mb-4">
                ¡Estamos para asesorarte! Puedes contactarnos de las
                siguientes maneras:
              </p>
              <ul className="text-gray-600 list-disc list-inside">
                <li>
                  <strong>Teléfono:</strong> +57 304 216 9147
                </li>
                <li>
                  <strong>Email:</strong> reservaexperiencia210416@gmail.com
                </li>
                <li>
                  <strong>Dirección:</strong> Av. 37c #61-08 Hermosa Provincia,
                  Bello, Antioquia
                </li>
              </ul>

              <div className="mt-8">
                <h3 className="text-xl font-semibold mb-4">
                  Síguenos en redes sociales
                </h3>
                <div className="flex justify-center space-x-6">
                  <a
                    href="https://www.facebook.com/reservaexperiencia/"
                    className="text-blue-600 hover:text-blue-800 transition duration-300"
                  >
                    <FaFacebook size={32} />
                  </a>
                  <a
                    href="https://www.instagram.com/reserva.experiencia/"
                    className="text-pink-600 hover:text-pink-800 transition duration-300"
                  >
                    <FaInstagram size={32} />
                  </a>
                </div>
              </div>
            </div>
          </div>

          {/* Enlace a WhatsApp */}
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-8">
        <div className="max-w-6xl mx-auto px-4 text-center">
          <div className="flex flex-raw items-center justify-center mb-4">  
            <div className="text-2xl font-bold text-blue-600 w-20 h-20 justify-center items-center flex rounded-full p-2">
              <img
                src="Logo.png"
                width={80}
                alt="Logo"
              />
            </div>        
            <div className="font-bold">
              <div className="text-2xl text-white">
              Reserva Experiencia
              </div>  
              <p className="text-gray">
                RNT 47415
              </p>
            </div>
          </div>
          <div className="flex justify-center">
          <div className="flex flex-raw justify-center space-x-6 mb-4 text-gray-500 w-3/5" >
            <div className="flex-colum w-1/2">
              <div>
                <a href="https://reservaexperiencias.s3.us-east-1.amazonaws.com/Sobre+nosotros.pdf"
                className="hover:text-blue-400">
                Sobre nosotros
                </a>
              </div>
              <div>
                <a href="https://reservaexperiencias.s3.us-east-1.amazonaws.com/Ley+de+Protecci%C3%B3n+de+Datos.pdf"
                className="hover:text-blue-400">
                Protección de datos
                </a>
              </div>
              <div>
                <a href="https://reservaexperiencias.s3.us-east-1.amazonaws.com/Sostenibilidad.pdf"
                className="hover:text-blue-400">
                Sostenibilidad
                </a>
              </div>
            </div>
            <div className="flex-colum w-1/2">
              <div>
                <a href="https://reservaexperiencias.s3.us-east-1.amazonaws.com/Terminos+y+condiciones.pdf"
                className="hover:text-blue-400">
                Términos y condiciones
                </a>
              </div>
              <div>
                <a href="https://reservaexperiencias.s3.us-east-1.amazonaws.com/autorizacion+viajes+ninos+ninas+adolescentes+2025.pdf"
                className="hover:text-blue-400">
                Autorización niños, niñas y adolescentes
                </a>
              </div>
            </div>
          </div>
          </div>

          <div className="flex justify-center space-x-6 mb-4">
            <a
              href="https://www.facebook.com/reservaexperiencia/"
              className="hover:text-blue-400"
            >
              Facebook
            </a>
            <a
              href="https://www.instagram.com/reserva.experiencia/"
              className="hover:text-blue-400"
            >
              Instagram
            </a>
            <a
              href="https://wa.me/c/573045804072"
              className="hover:text-blue-400"
            >
              WhatsApp
            </a>
          </div>
          <p className="text-gray-500">
            © 2024 Alison Duque Cardona LLC. Todos los derechos reservados.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default TravelAgencyLandingPage;
